<template>
	<div class="flexCloumn">
		 <el-table v-tableFit :data="tableData" height="100%" size="small" stripe border style="width:fit-content;width:100%;"
			@selection-change="handleSelectionChange">
			 <el-table-column v-if="$store.state.pmid == 214" type="selection" width="55"></el-table-column>
			<el-table-column prop="index" width="65" label="序号" align="center">
				<template slot-scope="scope">
					{{ scope.$index + 1 + ((Page.current_page - 1) * Page.per_page) }}
				</template>
			</el-table-column>
			<el-table-column prop="username" label="头像" width="70" align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.avatar" class="thumbnail" :style="'background-image:url(' + scope.row.avatar + ')'"
						style="width: 32px; height: 32px;background-position: center,center;background-size:cover;background-repeat: no-repeat;">
					</div>
					<!--					<img :src="scope.row.avatar" v-if="scope.row.avatar"-->
					<!--						style="width: 32px; width: 32px; vertical-align: top;">-->
				</template>
			</el-table-column>
			<el-table-column prop="username" label="教师姓名" width="120" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.openid" style="color: #007AFF; cursor: pointer;"
						@click="unbindTeacher(scope.row)">{{ scope.row.username }}</span>
					<span v-else>{{ scope.row.username }}</span>
					<i class="el-icon-link" title="已绑定微信" v-if="scope.row.openid"></i>
				</template>
			</el-table-column>
			<el-table-column prop="loginname" label="工号" show-overflow-tooltip width="120" align="center"></el-table-column>
			<el-table-column prop="phone" label="手机号" show-overflow-tooltip width="135" align="center"></el-table-column>
<!--			<el-table-column prop="job_state" label="状态" width="80" align="center">
				<template slot-scope="scope">
					<el-tag size="mini" v-if="scope.row.job_state == 1">在职</el-tag>
					<el-tag size="mini" v-if="scope.row.job_state == 2">离职</el-tag>
					<el-tag size="mini" v-if="scope.row.job_state == 3">调岗</el-tag>
					<el-tag size="mini" v-if="scope.row.job_state == 4">其他</el-tag>
				</template>
			</el-table-column>-->
			<el-table-column prop="index" min-width="100" label="部门" show-overflow-tooltip align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.dept_roles && scope.row.dept_roles.length > 0" @click="showOneDept(scope.row)"
						style="cursor: pointer;">

						<el-tag size="mini" v-for="(d, idx) in scope.row.dept_roles" :key="idx" style="margin-right:5px">
							{{ d.name }} {{ d.role_name }}</el-tag>

					</div>
					<div v-else @click="showOneDept(scope.row)" style="cursor: pointer;color: #67c23a">
						未设置
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="index" min-width="100" label="角色" show-overflow-tooltip align="center">
				<template slot-scope="scope">
					<div v-if="scope.row.dept_roles && scope.row.dept_roles.length > 0" @click="showOneDept(scope.row)"
						style="cursor: pointer;">

						<el-tag size="mini" style="margin-right:5px" v-for="(d, idx) in scope.row.sys_roles" :key="idx">
							{{ d.role_name }}</el-tag>

					</div>
					<div v-else @click="showOneDept(scope.row)" style="cursor: pointer;color: #67c23a">
						未设置
					</div>
				</template>
			</el-table-column>



			<el-table-column v-if="from != 'dept'" prop="index" min-width="100" label="权限角色(点击设置)" show-overflow-tooltip
				align="center">
				<template slot-scope="scope">
					<div style="cursor: pointer;" v-if="scope.row.roles && scope.row.roles.length > 0"
						@click="showOneTeacher(scope.row)">
						<div class="roleitem" v-for="(d, idx) in scope.row.roles" :key="idx">
							<el-tag size="mini"> {{ d.role_name }}</el-tag>
						</div>

					</div>
					<div v-else @click="showOneTeacher(scope.row)" style="cursor: pointer;color: #67c23a">
						未设置
					</div>
				</template>
			</el-table-column>

			<el-table-column prop="class_names" label="所带班级/小组" show-overflow-tooltip width="200" align="center">

			</el-table-column>

       <el-table-column prop="sort" label="排序" width="80" align="center">
         <template slot-scope="scope">
           <div>{{ scope.row.sort }}</div>

         </template>
       </el-table-column>


			<!-- <el-table-column prop="index" width="100" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.locked" style="color: orangered;">
                        已删除
                    </div>
                    <div v-else>正常</div>
                </template>
            </el-table-column>
-->

			<el-table-column fixed="right" label="操作" width="250" align="center">
				<template slot-scope="scope">
					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
						<el-tag class="pointer" slot="reference" type="danger" size="mini"
							style="margin-right: 10px;">删除</el-tag>
					</el-popconfirm>
					<el-tag class="pointer" @click="handleModify(scope.row)" type="primary" size="mini">修改</el-tag>

					<el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
						iconColor="red" @confirm="resetPwd(scope.row)" placement="left" :title="default_pwd ? ('是否确定重置？默认密码:' + default_pwd): '是否确定重置？默认密码请在配置中查看!'">
						<el-tag class="pointer" slot="reference" type="warning" size="mini" :title="default_pwd ?('默认密码:'+default_pwd):'默认密码请在配置中查看'"
							style="margin-left: 10px">重置密码</el-tag>
					</el-popconfirm>

					<el-dropdown :split-button="false" trigger="click" type="text" class="dropdown el-dropdown-link">
						<a style="font-size: 12px;margin-left: 10px;cursor: pointer">
							更多<i class="el-icon-caret-bottom el-icon--right"></i>
						</a>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item @click.native="releaseDept(scope.row)" style="font-size: 12px;">解除部门关联
							</el-dropdown-item>
							<el-dropdown-item @click.native="exportPdfs(scope.row)" style="font-size: 12px;">导出实习手册
							</el-dropdown-item>
							<el-dropdown-item @click.native="exportPdfs_xf(scope.row)" style="font-size: 12px;">导出巡访手册
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</template>
			</el-table-column>
		</el-table>
		<el-pagination @current-change="handlePageChange" @size-change="handleSizeChange" :current-page="Page.current_page"
			:page-size="Page.per_page" :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
			layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
		</el-pagination>

		<Tform :teacher_id="editrowid" v-if="editrowid" :schoolYears="schoolYears">

		</Tform>
	</div>
</template>
<script>
import Tform from "./TeacherForm.vue"
export default {
	name: 'Table',
	components: { Tform },
	data() {
		return {
			editrowid: 0,
			Page: {},
			default_pwd:'',
		}
	},
	props: {
		page: {
			type: Object,
		},
		tableData: {
			type: Array,
			default: []
		},
		from: {
			type: String,
			default: ''
		},
		multSelect: {
			type: Boolean,
			default: true
		},
    schoolYears: {
      type: Array,
      default: [],
    },
	},
	watch: {
		page: function (n, o) {
			this.Page = n

		}
	},
	mounted() {
		this.Page = this.page
		this.getConfig()
	},
	methods: {
		getConfig() {
			 this.$http.post("/api/sys_sch_config_list", {
				keyword: "default_pwd",
				ktype: "ktype"
			}).then(res => {
				for (let item of res.data.data) {
						this.default_pwd = item.kvalue
				}
			})
		},
		handleModify(row) {
			this.editrowid = row.id
		},
		handleDel(row) {
			this.$emit('handleDel', row)
		},
		handleSelectionChange(val) {
			this.$emit('handleSelectionChange', val)
		},
		handlePageChange(e) {
			this.$$parent(this, "page").then(page => {

				page.current_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getTeachers")

			})

		},
		handleSizeChange(e) {

			this.$$parent(this, "page").then(page => {
				console.log(page)
				page.per_page = e
				this.$$parent(this, "page", page)
				this.$$parent(this, "getTeachers")

			})

		},
		showOneDept(e) {
			this.$$parent(this, "teacher_id", e.id)
			this.$$parent(this, "showTeacherInfo", true)
		},
		showOneTeacher(e) {
			this.$emit('showOneTeacher', e)
		},
		resetPwd(e) {
			this.$http.post("/api/reset_teache_pwd", {
				id: e.id
			}).then(res => {
				this.$message.success("密码已重置为"+res.data.pwd)
			})

		},
		releaseDept(e) {
			this.$emit('releaseDept', e)

		},
		exportPdfs(row) {
			let params = {
				"stuArr": [{
					"id": row.id,
					"name": row.username,
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/exportTchPdf", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		},
		exportPdfs_xf(row) {
			let params = {
				"stuArr": [{
					"id": row.id,
					"name": row.username,
					"class_id": row.class_id
				}],
				"pmid": window.pmid,
				"latest": 1
			}
			this.$http.post(this.$pdfUrl + "/exportTchPdf_xf", params).then(res => {
				this.$message({
					type: 'success',
					message: '生成成功!'
				});
				window.open(res.data.url, "_blank")
			})
		},
		saveSort(row) {
			this.editrowid = 0;
			this.$http.post("/api/set_teacher_sort", {
				id: row.id,
				sort: row.sort
			}).then(res => {
				this.$message.success("修改成功")
			})
		},
		unbindTeacher(row) {
			this.$confirm('解绑后该微信登陆将失效，是否解绑?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http.post("/api/teacher_unbindwx", {
					id: row.id
				}).then(res => {
					this.$message.success("解绑成功")

					this.$$parent(this, "getTeachers")
				})
			}).catch(() => {

			});

		}

	}
}
</script>
