<template>
    <div>
        <el-dialog title="教师信息" width="1000px" :visible.sync="visible" :close-on-click-modal="false" @close="closeDialog"
            custom-class="cus_dialog">
            <el-form :model="formData" ref="adminUserInfo" label-width="95px">
                <el-row v-for="(item, idx) in Dept2Roles" :key="idx">
                    <el-col :span="12">
                        <el-form-item label="所在部门:" required>
                            <el-cascader @focus="setDeptIdx(idx)" ref="firstCascader" :options="treeData"
                                :props="defaultProps" v-model="item.dept_id" :show-all-levels="false"
                                @change="handleCascaderChange" placeholder="请选择" filterable style="width: 80%">
                            </el-cascader>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="部门角色:" prop="dept_id">
                            <!-- <el-input class="elInput" v-model="formData.dept_id" placeholder="请选择部门"></el-input> -->
                            <el-select v-model="item.role_id" placeholder="请选择部门角色">
                                <el-option label="请选择部门角色" :value="0"></el-option>
                                <el-option v-for="(item, index) in item.roles" :key="index" :label="item.role_name"
                                    :value="item.role_id"></el-option>
                            </el-select>

                            <el-button size="small" type="text" style="margin-left:10px" @click="addDeptrole" title="添加部门">
                                <i class="el-icon-plus"></i></el-button>
                            <el-button size="small" type="text" style="margin-left:10px"
                                v-if="Dept2Roles.length > 1 && idx > 0" @click="removeDept(idx)" title="删除部门"> <i
                                    class="el-icon-minus"></i></el-button>

                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>



                    <el-col :span="12">
                        <el-form-item label="姓名:" required>
                            <el-input class="elInput" v-model="formData.username" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="职工号:" required>
                            <el-input class="elInput" v-model="formData.loginname" placeholder="请输入职工号"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="12">
                        <el-form-item label="手机号:" required>
                            <el-input class="elInput" v-model="formData.phone" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                    </el-col>


                    <el-col :span="12">
                        <el-form-item label="座机号">
                            <el-input class="elInput" v-model="formData.mobile" placeholder="请输入座机号"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="邮箱">
                            <el-input class="elInput" v-model="formData.email" placeholder="请输入邮箱"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职务">
                            <el-input class="elInput" v-model="formData.user_dept" placeholder="请输入职务"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="性别:" required>
                            <el-radio-group size="small" v-model="formData.sex">
                                <el-radio :label="1">男</el-radio>
                                <el-radio :label="2">女</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>



                    <el-col :span="8">
                        <el-form-item label="排序号" prop="sort" required>
                            <el-input-number :min="1" v-model.trim="formData.sort" placeholder="请输入排序号(数字)"
                                controls-position="right" />
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item label="是否锁定:" prop="locked">
                            <el-select v-model="formData.login_flag" placeholder="请选择">
                                <el-option label="正常" :value="0">正常</el-option>
                                <el-option label="锁定" :value="1">锁定</el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否班主任:">
                            <el-radio-group size="small" v-model="formData.is_master" @input="handleMasterChange">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="formData.is_master">
                        <el-form-item label="所在学年:">
                            <el-select v-model="formData.school_year" placeholder="请选择">
                                <el-option v-for="item in schoolYears" :key="item.value" :label="item.title"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>

                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="是否测试号:" prop="locked">
                            <el-radio-group size="small" v-model="formData.is_testaccount">
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="0">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="22">
                        <el-form-item label="系统角色:" prop="locked">
                            <el-checkbox-group v-model="teacherAuth">
                                <el-checkbox :label="item.id" v-for="(item, idx) in sysAuth" :key="idx">
                                    {{ item.role_name }}</el-checkbox>

                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>
            <el-row slot="footer">
                <el-button size="small" type="default" @click="closeDialog">取消</el-button>
                <el-button size="small" type="primary" @click="saveForm">保存</el-button>
            </el-row>
        </el-dialog>

    </div>
</template>
<script>
import api from '@/api/api';
import moment from 'moment'

export default {
    name: 'Form',
    //props: ["teacher_id"],
    data() {
        var checkPhone = (rule, value, callback) => {
            if (!value) {
                return callback(new Error('手机号不能为空'));
            } else {
                const reg = /^1[3|4|5|7|8][0-9]\d{8}$/

                if (reg.test(value)) {
                    callback();
                } else {
                    return callback(new Error('请输入正确的手机号'));
                }
            }
        };

        return {
            visible: true,
            formData: {
                username: "",
                sex: "",
                loginname: "",
                phone: "",
                mobile: "",
                login_flag: 0,
                is_testaccount: 0,
                email: "",
                user_dept: "",
                sort: 99,
                is_master: 0,
                school_year: ""
            },
            treeData: [],
            defaultProps: {
                label: 'name',
                value: 'id',
                children: 'children',
                emitPath: false,
                checkStrictly: true
            },
            Dept2Roles: [], //部门
            deptIdx: -1,
            sysAuth: [],
            teacherAuth: []

        }
    },
    props: {
        teacher_id: {
            type: Number,
            default: 0
        },
        dept_id: {
            type: Number,
            default: 0
        },
        schoolYears: {
            type: Array,
            default: []
        }
    },
    mounted() {

        this.getOrgList()
        this.getSysRole()
        if (!this.teacher_id) {
            this.Dept2Roles = [{ dept_id: this.dept_id, role_id: 0, roles: [] }]
            this.initDeptRoles(this.dept_id)
        } else {
            this.$http.post("/api/get_teacher_info", { teacher_id: this.teacher_id }).then(res => {
                this.formData = res.data
                this.teacherAuth = res.data.sys_roles.map(e => { return e.role_id })
                if (res.data.dept_roles && res.data.dept_roles.length > 0) {
                    this.Dept2Roles = res.data.dept_roles
                    this.initDeptRoles()
                } else {
                    this.Dept2Roles = [{ dept_id: this.dept_id, role_id: 0, roles: [] }]
                    this.initDeptRoles(this.dept_id)
                }

                if (!this.formData.is_master) {
                    for (let t of this.teacherAuth) {
                        let r = this.sysAuth.filter(s => s.id === t && s.role_code === 'bzr')
                        if (r.length) {
                            this.formData.is_master = 1
                            this.handleMasterChange()
                            break
                        }
                    }
                }
            })
        }
    },
    methods: {

        addDeptrole() {
            this.Dept2Roles.push({ dept_id: this.dept_id, role_id: 0, roles: [] })
            this.initDeptRoles(this.dept_id)
        },
        initDeptRoles(dept_id) {
            for (let i = 0; i < this.Dept2Roles.length; i++) {
                let item = this.Dept2Roles[i]
                this.$http.post("/api/sys_dept_role_related_list", { dept_id: item.dept_id ? item.dept_id : dept_id }).then(res => {
                    if (res.data.data.length > 0) {
                        this.Dept2Roles[i].roles = res.data.data
                    }


                    this.$forceUpdate()
                    console.log(this.Dept2Roles)
                })
            }
            setTimeout(() => {
                this.$forceUpdate()
            }, 500);
        },
        getDeptRole(e) {
            this.$http.post("/api/sys_dept_role_related_list", { dept_id: e }).then(res => {
                this.Dept2Roles[this.deptIdx].roles = res.data.data
                this.$forceUpdate()

            })
        },
        removeDept(idx) {

            if (this.Dept2Roles[idx].id) {
                this.$confirm('确认删除所在部门?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    this.$http.post("/api/sys_dept_user_related_delete", { id: this.Dept2Roles[idx].id }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                        this.Dept2Roles.splice(idx, 1)
                        this.$parent(this, "getList")

                    })



                })
            } else {
                this.Dept2Roles.splice(idx, 1)
            }
        },
        //tree
        getOrgList() {
            this.$http.post("/api/sys_office_tree", { parent_id: 0, not_type: "'sxxz'" }).then(res => {
                this.treeData = res.data.tree

            })
        },

        closeDialog() {
            this.visible = false
            this.$$parent(this, "editrowid", 0)
            this.$$parent(this, "showTeacherInfo", false)

        },

        handleCascaderChange(e) {

            this.getDeptRole(e)
        },
        setDeptIdx(idx) {
            this.deptIdx = idx
        },
        getSysRole() {
            this.$http.post("/api/sys_role_list", { pagesize: 100 }).then(res => {
                this.sysAuth = res.data.data
            })
        },

        saveForm() {

            if (!this.formData.username.trim()) {
                this.$message.error("请输入姓名")
                return
            }
            if (!this.formData.loginname.trim()) {
                this.$message.error("请输入职工号")
                return
            }
            if (!this.formData.phone.trim()) {
                this.$message.error("请输入手机号")
                return
            } else {
                var myreg = /^[1][2,3,4,5,6,7,8,9][0-9]{9}$/;
                if (!myreg.test(this.formData.phone)) {
                    this.$message.error('请输入正确的手机号');
                    return
                }
            }
            if (!this.formData.sex) {
                this.$message.error("请选择性别")
                return
            }
            if (this.formData.email && this.formData.email.trim()) {
                var emailPat = /^(.+)@(.+)$/;
                var matchArray = this.formData.email.trim().match(emailPat);
                if (matchArray == null) {
                    this.$message.error("请输入正确的电子邮箱")
                    return
                }


            }
            for (let item of this.Dept2Roles) {
                if (!item.dept_id) {
                    this.$message.error("请选择所在部门")
                    return
                }
            }

            this.$http.post("/api/t_sch_teachers_edit", this.formData).then(res => {
                let teacher_id = 0
                if (res.data.id) {
                    teacher_id = res.data.id
                    for (let item of this.Dept2Roles) {
                        this.$http.post("/api/sys_dept_user_related_edit", { dept_id: item.dept_id, teacher_id: teacher_id, role_id: item.role_id, sort: 99, id: item.id }).then(res => { })
                    }
                } else {
                    teacher_id = this.teacher_id
                    for (let item of this.Dept2Roles) {
                        this.$http.post("/api/sys_dept_user_related_edit", { dept_id: item.dept_id, teacher_id: teacher_id, role_id: item.role_id, sort: 99, id: item.id }).then(res => { })
                    }
                }
                let role_ids = this.teacherAuth.join(",")

                this.$http.post("/api/sys_role_teacher_edit", { teacher_id: teacher_id, role_ids: role_ids }).then(res => { })
                this.$message({
                    type: 'success',
                    message: '保存成功!'
                });
                this.$$parent(this, "getTeachers")
                this.closeDialog()

            })


        },
        handleMasterChange() {
            if (this.formData.is_master) {
                if (!this.formData.school_year) {
                    let m = moment().format('M')
                    let nowYear = moment().year()
                    if (m >= 5) {
                        nowYear++
                    }
                    this.formData.school_year = (nowYear - 1) + "-" + nowYear
                }
            } else {
                this.formData.school_year = ""
            }
        },



    }
}
</script>
<style lang="less" type="text/less"></style>